@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: 'Poppins', sans-serif;
}

html,
body {
  font-family: 'Poppins', sans-serif;
}

.MuiTableCell-root {
  border: 1px solid rgba(224, 224, 224, 1);
}


::-webkit-scrollbar {
  display: none;
}
